import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/MWL_V1/css/MWL_V1.scss";

const Thankyou = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fullName = localStorage.getItem("FullName");
  localStorage.clear();
  return (
    <>
      <header className="thankupart">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 logo">
              <a href="#">
                <img src="/assets/MWL_V1/img/logo.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </header>
      <section className="thankusec">
        <div className="overly">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-left mb_bg">
              <h1>Thank You {fullName ? ", " + fullName + "." : ""}</h1>
              <p>
                One of our trusted advisors needs to speak with you on the phone
                briefly to get a bit more information.
              </p>
              <p>
                And don’t worry! This phone call does NOT commit you to buying
                anything and is simply to help you choose the best option.
              </p>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
        </div>
      </section>
      <footer className="text-center py-5">
        <div className="container">
          <div className="col-lg-12">
            <ul>
              <li>
                <Link
                  className="rp_clk"
                  to="terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms and Conditions
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link
                  className="rp_clk"
                  to="privacypolicy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Thankyou;

import { useState,useEffect } from 'react';
import "../../../assets/MWL_2C/css/MWL_2C.scss";
import Header from "../../Includes/Layouts/MWL_2C/Header";
import Footer from "../../Includes/Layouts/MWL_2C/Footer";
import FormMWL_V2B from "../../Forms/FormMWL_V2B";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import RingbaApi from '../../../Utility/RingbaApi';
import RingbaPhoneNumber from '../../../Utility/RingbaPhoneNumber';
import OfflineSpanishButtonLink from '../../../Utility/OfflineSpanishButtonLink';


const MWL_2C = () => {

  const campaign_name = 'english';
  const splitName = 'MWL_2C';
  const { response } = RingbaApi(campaign_name);
  let is_online = 'online';
  let redirect = response.redirect_path;
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get('utm_campaign');
  const [buttonRedirect, setButtonRedirect] = useState();
  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumber(utmName);

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MWL_2C" />
      <div className="mywillmyway secondwillsec">
        <Header />
        <section className="SECT bannergroup">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12 pad0" id="formpartsec">
                {/* <h1 className="d-block d-sm-none d-md-none d-lg-none">Secure<span className="headspan"> Your Professionally Written Will </span>From the Comfort of your Home</h1>
                <h2 className="d-block d-sm-none d-md-none d-lg-none">Get Your Will Written From <span className="text-decoration-line-through">£149.99</span> <span className="greenspan mx-1 text-white fw-bold"> £19.99 </span> </h2> */}
                <h1 className="d-block d-sm-none d-md-none d-lg-none">Simple, Smart<span className="headspan"> Online Wills </span></h1>
                <h2 className="d-block d-sm-none d-md-none d-lg-none text-style">Get a legally-binding Will to protect the people who matter most to you</h2>
                <div className="form_part">
                  {/* <h1 className="d-none d-sm-block d-md-block d-lg-block">Secure<span className="headspan"> Your Professionally Written Will </span>From the Comfort of your Home</h1> 
                  <h2 className="d-none d-sm-block d-md-block d-lg-block">Get Your Will Written From <span className="text-decoration-line-through"> £149.99 </span> <span className="greenspan px-3 mx-2 text-white fw-bold"> £19.99 </span> </h2>*/}
                  <h1 className="d-none d-sm-block d-md-block d-lg-block">Simple, Smart<span className="headspan"> Online Wills</span></h1>
                  <h2 className="d-none d-sm-block d-md-block d-lg-block text-style">Get a legally-binding Will to protect the people who matter most to you</h2>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-sm-none d-md-none d-block">
                    <span className="start-but" id="start-but">Start<br /><img src="/assets/MWL_V2B/img/arrow-b.png" alt="" /></span>
                  </div>
                  <div className="clearfix"></div>
                  <FormMWL_V2B formattedPhoneNumber={formattedPhoneNumber} phoneNumber={phoneNumber}/>
                </div>
                <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-12 my-3">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row m-0 p-0">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 texter">
                        <ul className="p-0">
                          <li className="tiksec">Thousands of Wills Already Written</li>
                        </ul>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 texter">
                        <ul className="p-0">
                          <li className="tiksec">Expert Agents at Your Fingertips</li>
                        </ul>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 texter">
                        <ul className="p-0">
                          <li className="tiksec">Professionally Written and Legally Binding</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </section>
        <section className="fut text-center text-lg-left text-white">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h2 className="text-center">Easy 3 Step Process</h2>
              <div className="futsection col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row m-0">
                  <div className="firstsec col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row m-0">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12 p-0">
                          <h3>1</h3>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                          <p>Fill in our online form to get the ball rolling. This will take less than a minute!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="secondsec col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12 p-0">
                          <h3>2</h3>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                          <p>Our in house experts will prepare your Will to meet all of your requirements.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="thirdsec col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12 p-0">
                          <h3>3</h3>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                          <p>Sit back and relax knowing your loved ones are looked after!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default MWL_2C;

import React, { useEffect, useState } from 'react';
import "../../../assets/PrivacyAndTerms/css/privacypolicy.scss"

const PrivacyPolicy = ({ showstatus, hidepopup }) => {
  return (
    <>
      <div>
        <section className="content-section text-start my-4">
          <div className="container">
            <div className="row ">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h1 className="mb-3">Privacy Policy</h1>
                <p>Mywillmyway.legal is a trading style of MCB Digital Solutions Ltd (MCB). It is committed to protecting the privacy and security of your personal data.</p>
                <p>MCB are located at Orega House, King Street, Manchester, M2 4NH. Company registration no. 07243953. MCB are registered with the ICO (ZA067327).</p>
                <p>This privacy notice describes how we collect, use and store personal information about you during and after your business relationship with us, in accordance with the General Data Protection Regulation (EU 2016/679) (GDPR).</p>
                <p>MCB is a “data controller”. This means that we are responsible for deciding how we hold and use and store personal information about you. We are required under the GDPR to notify you of the information contained in this privacy notice.</p>
                <p>We may update this notice at any time. If relevant (and feasible), we will notify you.</p>
                <p>It is important that you read this notice, together with any other privacy notice we may provide on specific occasions when we are collecting or processing personal information about you, so that you are aware of how and why we are using your personal information.</p>
                <h5>DATA PROTECTION PRINCIPLES</h5>
                <p>We will comply with all relevant data protection law (including the GDPR). This requires that the personal information we hold about you must be:</p>
                <p>1. Used lawfully, fairly and in a transparent way.</p>
                <p>2. Collected only for valid purposes that we have clearly explained to you and not used in any way that is incompatible with those purposes.</p>
                <p>3. Relevant to the purposes we have told you about and limited only to those purposes.</p>
                <p>4. Accurate and kept up to date.</p>
                <p>5. Kept only as long as necessary for the purposes we have told you about.</p>
                <p>6. Kept securely.</p>
                <h5>THE KIND OF INFORMATION WE COLLECT AND HOLD ABOUT YOU</h5>
                <p>Personal data, or personal information, means any information about an individual from which that person can be identified, whether directly or indirectly. It does not include data where an individual cannot be identified (anonymous data).</p>
                <p>We collect personal data including title, full name, address & postcode, telephone number, email address.</p>
                <p>This personal data is collected through the registration form that you complete on the following website.</p>
                <p>www.Mywillmyway.legal</p>
                <p>Please see our Cookie Notice for details of how cookies are used.</p>
                <div className="col-lg-12 col-12 border border-dark">
                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-4 col-6 border-end border-dark">
                        <p>Name</p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>Purpose</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Universal Analytics (Google)</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>_ga _gali _gat_UA-1036645-1 _gid</p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies collect information about how visitors use our website. We use the information to compile reports and to make improvements. The cookies collect information in an anonymous form, including where visitors have come to the website from and the pages they visited. To opt-out: <a href="https://tools.google.com/dlpage/gaoptout"> https:// tools.google.com / dlpage / gaoptout</a></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Taboola Retargeting</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies are used to tailor Taboola adverts to a user’s previous viewing habits. For instance, if you have previously visited our page, you may then see other adverts related to that product or service. Taboola is an ad network that shows sponsored content, usually as links below the article on major news sites.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Outbrain Retargeting</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies are used to tailor Outbrain adverts to a user’s previous viewing habits. For instance, if you have previously visited our page, you may then see other adverts related to that product or service. Outbrain is an ad network that shows sponsored content, usually as links below the article on major news sites.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Adwords Retargeting (Google)</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies are used to adjust the targeting of our paid search and display advertising to show ads to users who have previously been to our sites and/or engaged with our page such as completing a form. They contain no personal information.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>DoubleClick Retargeting</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies are used to adjust the targeting of our display advertising to show ads to users who have previously been to our sites and/or engaged with our page such as completing a form. They contain no personal information.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Taboola Conversion Tracking</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure ad conversions and optimise spend for advertising campaigns served on Taboola.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Outbrain Conversion Tracking</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure ad conversions and optimise spend for advertising campaigns served on Outbrain.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Yahoo Conversion Tracking</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure ad conversions and optimise spend for advertising campaigns served on Yahoo Gemini.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Revcontent Conversion Tracking</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure ad conversions and optimise spend for advertising campaigns served on Revcontent.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Adwords Conversion Tracking</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure when a user has completed an action on site such as completing a form. They contain no personal information.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Bing Conversion Tracking</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>UET Tag</p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure when a user has completed an action on site such as completing a form. They contain no personal information.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>DoubleClick Conversion Tracking</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure when a user has completed an action on site such as completing a form. They contain no personal information.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Facebook Pixel</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>
                          These cookies measure ad conversions, optimise and build audiences for advertising campaigns served on Facebook. To opt-out: <a href="https://www.facebook.com/policies/cookies/">https:// www.facebook.com / policies / cookies / </a></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Twitter Pixel</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies measure ad conversions and optimise advertising campaigns served on Twitter. To opt-out:<a href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads"> https: // help.twitter.com / en / safety-and-security / privacy-controls-for-tailored-ads</a></p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Adsense</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>AdSense uses cookies to improve advertising e.g. displaying targeted advertisements and avoiding showing advertisements that the user has already seen. These cookies contain no personally identifiable information.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Full Story</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>These cookies are used to collect information on a user’s use of the site, such as pages visited, links clicked, non-sensitive text entered, and mouse movements, as well as information more commonly collected such as the referring URL, browser, operating system, and Internet Protocol (“IP”) address.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12 border-bottom border-dark">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>Cake</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>
                          These cookies are used to register a unique ID in order to store a user’s conversion.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-12">
                    <div className="row m-0 p-0">
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p>AdRoll</p>
                      </div>
                      <div className="col-lg-2 col-3 border-end border-dark">
                        <p></p>
                      </div>
                      <div className="col-lg-8 col-6">
                        <p>The website uses personalised advertising cookies to ensure you get the best experience on our website.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <h5>HOW WE WILL USE INFORMATION ABOUT YOU</h5>
                <p>We will only use your personal information in the ways the law allows. Most commonly, we will use your personal information in the following circumstances:</p>
                <p>1. . Where you have given us consent for the processing of your data in relation to a landing page form-fill. In this instance, where you have provided your data to receive contact about Will writing, estate or life planning we will share your data with the following organisations:</p>
                <ul>
                  <li>Trust Family Associates Ltd.</li>
                </ul>
                <p>By sharing your information with these organisations, they may contact you in order to further your enquiries about Will Writing. You can withdraw your consent at any time by contacting us using the details below.</p>
                <p>info@mcbdigitalsolutionsltd.co.uk</p>
                <p>2. Where processing is required for the performance of a contract either party have entered</p>
                <p>3. Where processing is necessary for compliance with a legal obligation</p>
                <p>4. Where processing is necessary for the purposes of legitimate interests by us as data controller. If personal data is used in this instance, we will document our considerations in a legitimate interest assessment (LIA).</p>
                <h5>SITUATIONS IN WHICH WE WILL USE YOUR PERSONAL INFORMATION</h5>
                <p>We need all the categories of information detailed above primarily to allow us to contact you following your enquiry on one of our landing pages.</p>
                <p>If you are a client or supplier, then we will need to process your data in line with our legal obligations. In some cases, we may use your personal information to pursue legitimate interests of our own or those of third parties, provided your interests and fundamental rights do not override those interests. The situations in which we will process your personal information are listed below.</p>
                <ul>
                  <li>In order to enhance user experience</li>
                  <li>Business management and planning</li>
                  <li>Accounting and auditing</li>
                </ul>
                <p>Some of the above grounds for processing will overlap and there may be grounds which justify our use of your personal information.</p>
                <h5>DATA RETENTION</h5>
                <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
                <h5>RIGHTS OF ACCESS, CORRECTION, ERASURE & RESTRICTION</h5>
                <p>It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during your working relationship with us.</p>
                <p>Your Rights:</p>
                <p>Subject Access Request – this enables you to receive a copy of the personal information we hold about you. To action this request, please contact us at info@mcbdigitalsolutionsltd.co.uk email address. We require a suitable form of identification and under normal circumstances, we will supply this to you within 30 days. No fee is payable as long as the request is deemed to be excessive or repetitive.</p>
                <p>Request Correction – this enables you to have any incomplete or inaccurate information we hold about you corrected.</p>
                <p>Request Erasure – this enables you to delete or remove personal information when there is no reason for us to continue processing it.</p>
                <p>Object to Processing – in certain circumstances, you have the right to request we stop processing your data or limit the ways we process your data.</p>
                <p>Request a Transfer – you have the right to request the transfer of your personal data to a third party where appropriate.</p>
                <p>Right to Withdraw Consent – where we rely on consent to process your data, you have the right to withdraw this at any time, without giving reason. To withdraw your consent, please contact info@mcbdigitalsolutionsltd.co.uk. Once received, we will not process your data for the reasons you have agreed to, unless we have another legal basis for doing so.</p>
                <p>Right to complain – you have the right to complain at any time to the Information Commissioners’ Office (ICO) regarding data protection issues.</p>
                <p>We reserve the right to update this privacy notice at any time. If feasible, we will make you aware of these updates. If you have any questions about this privacy notice, please contact us on info@mcbdigitalsolutionsltd.co.uk.</p>
                <p>Or write to:</p>
                <p>Information Commissioner's Office</p>
                <p>Wycliffe House</p>
                <p>Water Lane</p>
                <p>Wilmslow</p>
                <p>Cheshire</p>
                <p>SK9 5AF</p>
                <p>Policy last updated Nov 2022</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PrivacyPolicy;
import React, { useCallback, useContext, useState } from "react";
import ProgressBar from "./ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
import QueryStringContext from "../../../../Contexts/QueryString";
import arrow from "../../../../assets/MWL_V2B/img/arrow.png";
import icosafe from "../../../../assets/MWL_V2B/img/ico-safe.png"

const BasicQuestionnaireFour = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  validation,
  getValues,
  splitName = ""
}) => {
  const { queryString } = useContext(QueryStringContext);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion } = useDataIngestion();
  const nextSlide = async (e) => {
    changeActiveStatus(e);
    slideChange(e);
  };

  const [activestatus, setActivestatus] = useState(0);
  const changeActiveStatus = (e) => {
    if (e.target.value == 12) {
      setActivestatus(12);

    } else if (e.target.value == 13) {
      setActivestatus(13);
    }
  }
  const proceed = () => {
    window.location.href = "https://siliconmarket.org/?a=31&c=2007";
  };
  const onRedirect = useCallback(async () => {
    const { born_before, marital_status, children, homeowner } = getValues();
    const formData = {
      born_before,
      marital_status,
      children,
      homeowner,
    };
    console.log("formData values", { formData });
    try {
      const response = await saveDataIngestion(
        visitorParameters?.visitor_parameters,
        formData,
        "wills_lp_submit",
        splitName,
        queryString,
        visitorParameters?.data
      );
      console.log({ response }, response.data, response.data.status);
      if (response.status !== 200) return;
      proceed();
    } catch (error) {
      console.log({ error });
      return;
    }
  }, [
    splitName,
    getValues,
    saveDataIngestion,
    visitorParameters?.data,
    visitorParameters?.visitor_parameters,
    queryString,
  ]);
  return (
    <>
      <div id="slide4" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="50" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row">
              <div className="form-group">
                <label className="text-center mb-1">{textHeading}</label>
                <RadioButton
                  tabIndex="1"
                  value="12"
                  id="homeowner_yes"
                  name="homeowner"
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="Yes, I own my home"
                  labelClassName={(activestatus == 12 ? "active " : "") + "icon_option smoker3 m_family load sctop next04"}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  tabIndex="2"
                  value="13"
                  id="homeowner_no"
                  name="homeowner"
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="No, I rent"
                  labelClassName={(activestatus == 13 ? "active " : "") + "icon_option smoker3 m_family load mobload sctop next04"}
                  // onClick={onRedirect}
                  onClick={nextSlide}
                  validation={validation()}
                />
              </div>
              <span id="fullDOB_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="col-12 text-center mt-1">
            <a id="back3" name="back3" className="back_btn sctop1" onClick={slidePreChange}><img src={arrow} alt="" />  Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src={icosafe} alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireFour;

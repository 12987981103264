import React, { useState, useRef } from "react";
import ProgressBar from "./ProgressBar";
import CustomCheckBox from "../../../UI/CustomCheckBox";

const BasicQuestionnaireThree = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  checkSeekingWillList,
  formSubmit
}) => {
  const [seekingWillList] = useState([]);
  const [showError, setError] = useState('hide');
  const nextSlide = async (e) => {
    if (checkBoxValidation()) {
      formSubmit();
      slideChange(e);
    }
  };

  const scrollSlide10 = useRef(null);

  const onSelectSeekingWill = (e) => {
    if (e.target.checked === true) {
      seekingWillList.push({ value: e.target.value });
      checkBoxValidation();
    }
    else {
      seekingWillList.pop({ value: e.target.value });
      checkBoxValidation();
    }
    checkSeekingWillList(seekingWillList);
  };

  const checkBoxValidation = (e) => {
    if (seekingWillList.length > 0) {
      setError('hide');
      return true;
    }
    window.scrollTo({
      top: scrollSlide10.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    setError('show');
    return false;

  }
  return (
    <>
      <div ref={scrollSlide10} id="slide10" className={`col-12 ${className}`}>
        <ProgressBar value="40" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <label>{textHeading}</label>
            <p className="text-center paragrap">Please check all answers that apply</p>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 col-12">
                  <ul className="options">
                    <li className="popupLink mt-3">
                      <CustomCheckBox
                        value="19"
                        id="chkSeekingWill1"
                        labelText="Ensure your property is given to your loved ones"
                        name="chkSeekingWill1"
                        labelClassName="defaultchk"
                        dataId="chkSeekingWill1"
                        onChange={onSelectSeekingWill}
                      />
                    </li>
                    <li className="popupLink">
                      <CustomCheckBox
                        value="20"
                        id="chkSeekingWill2"
                        labelText="Minimise the burden on loved ones at a time of bereavement"
                        name="chkSeekingWill2"
                        labelClassName="defaultchk"
                        dataId="chkSeekingWill2"
                        onChange={onSelectSeekingWill}
                      />
                    </li>
                    <li className="popupLink">
                      <CustomCheckBox
                        value="21"
                        id="chkSeekingWill3"
                        labelText="Reduce inheritance tax & increase the benefit for your heirs"
                        name="chkSeekingWill3"
                        labelClassName="defaultchk"
                        dataId="fnrlpln3"
                        onChange={onSelectSeekingWill}
                      />
                    </li>
                    <li className="popupLink">
                      <CustomCheckBox
                        value="22"
                        id="chkSeekingWill4"
                        labelText="Ensure gifts are passed to on to the correct people"
                        name="chkSeekingWill4"
                        labelClassName="defaultchk"
                        dataId="fnrlpln4"
                        onChange={onSelectSeekingWill}
                      />
                    </li>
                  </ul>
                  <span id="fullDOB_err" className={`error_msg error ${showError}`} >Please Select atleast one.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <input type="button" id="next10" name="next10" className="next-but btn flasher my-bts sctop1 next03" defaultValue="Next >>" autoComplete="off" onClick={nextSlide} />
          <div className="col-12 text-center mt-1">
            <a id="back8" name="back8" className="back_btn sctop" onClick={slidePreChange}><img src="/assets/MWL_V2/img/arrow.png" alt=""/>  Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src="/assets/MWL_V2/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireThree;

import React from "react";

const ProgressBar = ({ value }) => {
  const percentageValue = (value == '0') ? 'START' : value + "%";
  const content = (value == '0%') ? '' : 'completed';
 {
    return (
      <div className="col-lg-12 progrss mt-3 px-3 pt-3">
        <div className="progress">
          <div className="progress-bar" id="progressBar" style={{"width" : value + "%"}}></div>
        </div>
        <p className="text-center prgrs_txt" id="progressBar_content">Progress {value}%</p>
      </div>
    );
  }
};
export default ProgressBar;

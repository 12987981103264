import React, { useState } from "react";
import {
  DobDay,
  DobMonth,
  DobYear,
} from "../../../../Constants/MWL_V2/Constants";
import SelectBox from "../../../UI/SelectBox";
import BirthDateModal from "./BirthDateModal";
import ProgressBar from "./ProgressBar";
import arrow from "../../../../assets/MWL_V2B/img/arrow.png";
import icosafe from "../../../../assets/MWL_V2B/img/ico-safe.png"

const DOB = ({
  className,
  slidePreChange,
  slideChange,
  textHeading,
  validation,
  validationMsg,
  trigger,
  splitForm,
  setError,
}) => {
  const [modalClass, SetModalClass] = useState("modal fade");
  const nextSlide = async (e) => {
    const form = splitForm.current;
    console.log(form["DobDay"].value);
    let errorFlag = 0;
    var txtDobDay = await trigger("DobDay");
    var txtDobMonth;
    var txtDobYear;
    var flag = 1;
    if (form["DobDay"].value < 1 || form["DobDay"].value > 31) {
      flag = 0;
    }
    if (txtDobDay) {
      txtDobMonth = await trigger("DobMonth");
    } else {
      return false;
    }
    if (txtDobMonth) {
      if (flag == 0) {
        setError("DobMonth", {
          type: "manual",
          message: "Please Enter Valid Day",
        });
        return false;
      } else {
        txtDobYear = await trigger("DobYear");
      }
    } else {
      return false;
    }
    if (!txtDobMonth || !txtDobDay || !txtDobYear) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };
  const handleChange = (e) => {
    const input = e.target.value;
    if (input != "") {
      if (input < 1 || input > 31) {
        setError("DobDay", {
          type: "manual",
          message: "Please Enter Valid Day",
        });
      }
    }
  };
  const showModal = (e) => {
    document.body.classList.add("no-scroll");
    document.getElementById("analayzeModal").classList.add("show");
    document.getElementById("analayzeModal").classList.remove("hide");
    SetModalClass("modal show");
  };
  return (
    <>
      <div id="slide7" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="80" />
        <h3>{textHeading}</h3>

        <div className="form-group px-3 mt-3">
          <div className="col-lg-12 text-center">
            <div className="row">
              <div className="col-12 col-md-4 mb-2">
                <SelectBox
                  className="form-select form-control mt-0"
                  OptionValue={DobDay}
                  name="DobDay"
                  id="DobDay"
                  onBlur={handleChange}
                  
                  myRef={validation({
                    required: "Please Select Day",
                  })}
                  validationMsg={
                    validationMsg.DobDay && validationMsg.DobDay.message
                  }
                />
              </div>

              <div className="col-12 col-md-4 mb-2">
                <SelectBox
                  className="form-select form-control mt-0"
                  OptionValue={DobMonth}
                  name="DobMonth"
                  id="DobMonth"
                  myRef={validation({
                    required: "Please Select Month",
                  })}
                  validationMsg={
                    validationMsg.DobMonth && validationMsg.DobMonth.message
                  }
                />
              </div>

              <div className="col-12 col-md-4 mb-2">
                <SelectBox
                  className="form-select form-control mt-0"
                  OptionValue={DobYear}
                  name="DobYear"
                  id="DobYear"
                  myRef={validation({
                    required: "Please Select Year",
                  })}
                  validationMsg={
                    validationMsg.DobYear && validationMsg.DobYear.message
                  }
                />
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
              <input
                type="button"
                id="next07"
                name="next07"
                className="next-but btn my-bts flasher2 sctop1 next06"
                defaultValue="Continue >>"
                autoComplete="off"
                onClick={nextSlide}
              />
              <div className="col-12 text-center mt-1">
                <a
                  id="back6"
                  name="back6"
                  className="back_btn sctop1"
                  onClick={slidePreChange}
                >
                  <img src={arrow} alt="" /> Previous{" "}
                </a>
              </div>
              <div className="text-center safe-text">
                <img
                  src={icosafe}
                  alt="Safe, Secure &amp; Confidential"
                  width="12"
                  height="15"
                />
                Safe | Secure | Privacy Protected
              </div>
            </div>
          </div>
        </div>
        <BirthDateModal modalClass={modalClass} />
      </div>
    </>
  );
};

export default DOB;

import { useCallback } from "react";
import { Api } from "../api/Api";

export const usePhoneValidation = () => {
  const phoneValidation = useCallback(async (phone, uuid = null) => {
    if (!uuid) {
      const urlParams = new URLSearchParams(window.location.search);
      uuid = urlParams.get("uuid") ?? localStorage.getItem("uuid");
    }

    const getValidation = await Api.get(`v1/get-phone-validation`, {
      params: {
        phone,
        uuid,
      },
    });

    return getValidation;
  }, []);

  return { phoneValidation };
};

import { useContext } from "react";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { useDataIngestion } from "../Hooks/useDataIngestion";
import { queryString } from "./QueryString";

const PixelTrigger = () => {
  const { saveDataIngestion } = useDataIngestion();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const tyPixelTrigger = ({ splitName }) => {
    const formData = {
      lstSalutation: '',
      txtFName: '',
      txtLName: '',
      txtEmail: '',
      txtPhone: '',
      page_name: splitName,
    };

    saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      "pixel_message",
      splitName,
      queryString,
      visitorParameters.data
    );
  }

  return tyPixelTrigger;
};

export default PixelTrigger;
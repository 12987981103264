import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';
import { Helmet } from 'react-helmet';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <Helmet>
        <script src='//b-js.ringba.com/CA9210a67e39d64107bc0a26ee4ae80487' async></script>
      </Helmet>
      <header id="page-top">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <div className="row m-0">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-start">
                <img src="/assets/MWL_V2B/img/logo.png" alt="" className="logo" />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-6 text-end">
                <img src="/assets/MWL_V2B/img/trustpilot.jpg" alt="" className="logo1" />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

  return (
    <>
      <footer className="text-center p-5">
        <div className="container">
          <div className="col-lg-12">
            <ul>
              <li><Link className="rp_clk" to="terms" target="_blank" rel="noopener noreferrer" >Terms and Conditions</Link></li>
              <li>|</li>
              <li><Link className="rp_clk" to="privacypolicy" target="_blank" rel="noopener noreferrer" >Privacy Policy</Link></li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
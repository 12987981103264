import React, { useState } from "react";
import "../../../assets/MWL_ADV_V1/css/MWL_ADV_V1.scss";
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import AdvFooter from "../../Includes/Layouts/MWL_ADV_V1/AdvFooter";
import AdvHeader from "../../Includes/Layouts/MWL_ADV_V1/AdvHeader";
import { Months } from "../../../Constants/Constants"

const MWL_ADV_V1 = () => {
  const [showCookie, setshowCookie] = useState('show');
  var now = new Date();
  const year = now.getFullYear()
  const date = now.getDate()
  const monthIndex = now.getMonth();
  const monthName = Months[monthIndex];
  const formatted = ` ${monthName} ${date}, ${year}`;
  const [showTime, setShowTime] = useState(formatted);
  var cookieName = "CookiesPolicyHide";
  const cookieClose = (e) => {
    e.preventDefault();
    setshowCookie('hide');
    if (cookieName != null) {
      var d = new Date();
      var cvalue = "Yes";
      d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cookieName + "=" + cvalue + "; ";
      setshowCookie('hide');
    }
  }

  let years = [];
  for (var i = 1935; i <= 1980; i++) {
    years[i] = i;
  }
  const listYears = years.map((years) =>
    <AnchorAdv key={years} dataId="MID_PARA_NUM">{years}</AnchorAdv>
  );

  return (
    <>
      <AdtopiaLoadAP pageType="AP" splitName="MWL_ADV_V1" />
      <div className="MWL_ADV_V1">
        <div>
          <AdvHeader />
          {/* <!-- content_part start --> */}
          <section className="content_part mx-auto">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 p-0 m-0">
                  <div className="whitesec w-100">
                    <div className="whitebox bg-white mx-auto">
                      <div className="row justify-content-center">
                        <div className="col-lg-9 col-md-7 col-sm-7 col-12 firstsec">
                          <h1>No Will? Don't Let the Government 'Dictate What Happens' to Your Money when You're Gone!</h1>
                        </div>
                        <div className="col-lg-3 col-md-5 col-sm-5 col-12 text-center secondsec">
                          <AnchorAdv dataId="CONTENTBTN">
                            <button type="button" className="btn contentbtn text-white rounded-0 w-100">
                              Get A Quick Quote<br />
                              <span className="btntext">
                                Save 67% Limited Time Offer
                              </span>
                            </button>
                          </AnchorAdv>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- content_part end --> */}
          <section className="textarea">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <h3 className="my-3">Don't be a part of the 27 million UK citizens living without a will. End the unnecessary risk from the comfort of your own home right now for less than £100!*</h3>
                <p className="mt-3">If you’ve always thought it was too early to get a secure, legally binding will, you may not know what can happen to all your hard earned assets and precious possessions in the case of an unexpected tragedy.</p>
                <p className="mt-3">Should the worst happen, some or all of the below could be faced by your family:</p>
                <ul>
                  <li className="my-3">
                    The UK government could decide what happens to your assets, leaving your family without a say.
                  </li>
                  <li className="my-3">
                    Your family could spend the money you left behind on lengthy legal battles to control your estate.
                  </li>
                  <li className="my-3">
                    A spike to your inheritance tax, erasing even more assets your family could have held.
                  </li>
                  <li className="my-3">
                    Your property could be carelessly handed out by the government.
                  </li>
                  <li className="my-3">
                    Your life could be diminished to ‘dated’ government standards.
                  </li>
                </ul>
                <p className="mt-3">If you don’t have a legally binding Will securing the division of your assets, you may leave your family with years of legal struggles and nothing left in the end. Let your family live with great memories of you, by <b><u>
                  <AnchorAdv dataId="CONTENTBTN">
                    making sure you have a Will in place, just in case of an emergency.
                  </AnchorAdv>
                </u></b></p>
              </div>
            </div>
          </section>
          <section className="partnersec text-center py-3">
            <div className="container">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-12 age_button_sec text-center">
                    <div className="age_button_inner">
                      <div className="age_button_inner_bg">
                        <h2> Need A Professional Will? Get A Free Quote From the Comfort of Your Home - Click Your Birth Year Below!</h2>
                        {listYears}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="reasontext">
            <div className="container">
              <div className="col-lg-12">
                <p className="mt-3">There are many reasons you may not have a Will yet:</p>
                <ul>
                  <li className="my-3">You think you’re too young to be worried about death</li>
                  <li className="my-3">The high-street solicitor fees are completely unreasonable</li>
                  <li className="my-3">You’re worried it will take too much time to have one written</li>
                  <li className="my-3">You don’t understand what could happen if you don’t secure your assets</li>
                </ul>
                <p>If any of the above are holding you back, it’s time to reconsider. With one click, you could&nbsp;
                  <AnchorAdv dataId="MID_PARA_NUM">
                    <b><u>get your FREE quote</u></b>
                  </AnchorAdv>
                  &nbsp;and be on your way to having an expert Will writer helping you make sure your inheritance is secured and your family’s financial future is safe. </p>
                <p>Take control of your end of life arrangements right now, before it’s time, so the government can’t take advantage later.</p>
                <AnchorAdv dataId="QUOTEBTN">
                  <button type="button" className="btn quotebtn text-white w-100 rounded-0 py-3">Click Here To Get A Free Quote</button>
                </AnchorAdv>
                <h3 className="my-3">Top 5 Reasons To Get Your Will Written RIGHT NOW:</h3>
                <ol>
                  <li className="my-3">Ensure a secure financial future for your family</li>
                  <li className="my-3">Reduce the stress on loved ones during a difficult time</li>
                  <li className="my-3">Protect your assets from the government’s discretion</li>
                  <li className="my-3">Control where valuable family heirlooms go to ensure their longevity</li>
                  <li className="my-3">Leave no questions or guessing behind for your family should the most unexpected happen</li>
                </ol>
                <h4 className="my-3 text-center">Select your area below to get started:</h4>
                <div className="mapsec text-center mt-3 mb-5">
                  <AnchorAdv dataId="MAIN_CTA_MAP">
                    <img src="/assets/MWL_ADV_V1/img/map.png" alt="" />
                  </AnchorAdv>
                </div>
                <p>While many think of Wills as a morbid and complicated affair, the reality is that they are an amazing way to help your family in the event of your passing. Leaving your final will and testament lets your family find peace, knowing that they are able to follow your final wishes if you did not have time to share them.</p>
                <h5 className="my-3 text-center">Who Will Be Hurt If You Pass Without Leaving a Will Behind?</h5>
                <ul>
                  <li className="my-3"><b>Your partner who was once in a previous marriage.</b> Even if their previous partner left 30+ years ago, they will still be handed the bulk of your partner’s wealth and left as the main decider of the assets left behind. </li>
                  <li className="my-3"><b>Your step children.</b> Even if you’ve raised them and lived them as your own, unless they are named in your will, they will be left with nothing should you pass away.</li>
                  <li className="my-3"><b>Your caretakers.</b> If you have no remaining family, you may want to ensure that friends, neighbors, and anyone else who cared for you, knows how much they meant to you.</li>
                </ul>
                <p>The government will not consider the lifelong relationships you have built in your community when dividing your assets. The rules are simple: If you are not named in the Will, you are not entitled to any of your lost loved one’s assets.</p>
                <p>Don’t let the stress of high-street solicitors and confusing lingo keep you from confirming your final wishes.&nbsp;
                  <AnchorAdv dataId="QUOTEBTN">
                    <b><u>Get your will written securely</u></b>
                  </AnchorAdv>
                  &nbsp;and know that your hard earned assets and priceless heirlooms are protected from the government.</p>
                <p>Thousands across the UK have stepped up and had their legally binding wills written by a professional.</p>
                <AnchorAdv dataId="QUOTEBTN">
                  <button type="button" className="btn quotebtn text-white w-100 rounded-0 py-3"> Click Here To Get A Free Quote</button>
                </AnchorAdv>
                <h5 className="my-3 text-start"><u>WITH</u> your Will:</h5>
                <p><span>✅</span> Provide clear, straightforward instruction for the distribution of your estate. Ensure that the executors of your estate are chosen by you, NOT the government. </p>
                <p><span>✅</span> Ensure that priceless family heirlooms are passed on to the next generation, not sold at an estate sale to pay off your debts. </p>
                <p><span>✅</span> Share the desires for your funeral and make sure your body is put to rest how and where you feel most at home.</p>

                <h5 className="my-3 text-start"><u>WITHOUT</u> your Will:</h5>
                <p><span>❌ </span> Your family will be forced to watch as the government divides your estate using 'outdated' laws</p>
                <p><span>❌ </span> Your family will be left wondering and guessing how you wanted to be left to rest.</p>
                <p><span>❌ </span> Your family could watch your hard earned money disappear between taxes and legal fees.</p>

                <h5 className="my-3 text-start">So What Now?</h5>
                <p><b>You COULD</b> ignore the tremendous risk and leave writing a will until later. Recognizing that your grieving family could be left with a mountain of stress, should you pass unexpectedly.</p>
                <p><b>You COULD</b> hire a solicitor to write your will, paying hundreds in the process.</p>
                <p><b>You COULD</b> write your own Will, even though the smallest mistake could erase all legality of this document, leaving the Government in charge of your final wishes.</p>
                <p><b><u>YOU SHOULD</u></b> Get a quick, easily affordable will that is securely written and legally binding.&nbsp;
                  <AnchorAdv dataId="QUOTEBTN">
                    <b><u>Get your final wishes in order NOW,</u></b>
                  </AnchorAdv>
                  &nbsp;before its <strong>LATE.</strong></p>
                <AnchorAdv dataId="QUOTEBTN">
                  <button type="button" className="btn quotebtn text-white w-100 rounded-0 py-3">Click Here To Get Your Free Quote</button>
                </AnchorAdv>
              </div>
            </div>
          </section>
          <AdvFooter />
        </div>
      </div>
    </>
  );
};

export default MWL_ADV_V1;

import React, { useState } from "react";
import ProgressBar from "../../Layouts/MWL_V2/ProgressBar";
import RadioButton from "../../../UI/RadioButton";

const BasicQuestionnaireTwo = ({
  className,
  slideChange,
  textHeading,
  slidePreChange,
  validation
}) => {
  const nextSlide = async (e) => {
    changeActiveStatus(e);
    slideChange(e);
  };

  const preSlide = async (e) => {
    document.getElementById("start-but").classList.remove('hide');
    slidePreChange(e);
  };

  const [activestatus, setActivestatus] = useState(0);
  const changeActiveStatus = (e) => {
    if (e.target.value == 3) {
      setActivestatus(3);

    } else if (e.target.value == 4) {
      setActivestatus(4);
    }
  }

  return (
    <>
      <div id="slide2" className={`col-12 top_minus_1 ${className}`}>
      <ProgressBar value="30" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row">
              <div className="form-group">
                <label className="text-center mb-1">{textHeading}</label>
                <RadioButton
                  tabIndex="1"
                  value="3"
                  name="marital_status"
                  id="marital_status_yes"
                  className="radio-button custom-radio-button sctop1 next-but"
                  labelName="Yes"
                  labelClassName={(activestatus == 3 ? "active " : "") + "icon_option smoker3 m_family load sctop1 next02"}
                  autoComplete="off"
                  onClick={nextSlide}
                  validation={validation()}
                />   
                <RadioButton
                  tabIndex="2"
                  value="4"
                  name="marital_status"
                  id="marital_status_no"
                  className="radio-button custom-radio-button sctop1 next-but"
                  labelName="No"
                  labelClassName={(activestatus == 4 ? "active " : "") + "icon_option smoker3 m_family load mobload sctop1 next02"}
                  autoComplete="off"
                  onClick={nextSlide}
                  validation={validation()}
                />
              </div>
              <span id="cover_for_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="col-12 text-center mt-1">
            <a id="back1" name="back1" className="back_btn sctop1"  onClick={preSlide}><img src="/assets/MWL_V2/img/arrow.png" alt=""/> Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src="/assets/MWL_V2/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireTwo;

import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import "./App.scss";
import PrivacyPolicy from "./Components/Includes/Layouts/PrivacyPolicy";
import Terms from "./Components/Includes/Layouts/Terms";
import MWL_ADV_V1 from "./Components/Pages/Advertorials/MWL_ADV_V1";
// import MWL_ADV_V2 from "./Components/Pages/Advertorials/MWL_ADV_V2";
import Analyse from "./Components/Pages/Analyse";
import FbPixel from "./Components/Pages/FbPixel";
import Followup from "./Components/Pages/Followup/Followup";
import HomePage from "./Components/Pages/HomePage";
import NotFound from "./Components/Pages/NotFound";
import Signature from "./Components/Pages/Signature";
import MWL_V1 from "./Components/Pages/Splits/MWL_V1";
import MWL_V2 from "./Components/Pages/Splits/MWL_V2";
import MWL_V2B from "./Components/Pages/Splits/MWL_V2B";
import MWL_V4 from "./Components/Pages/Splits/MWL_V4";
import Thankyou from "./Components/Pages/Thankyou";
import Unqualified from "./Components/Pages/Unqualified";
import { QueryStringProvider } from "./Contexts/QueryString";
import { RedirectProvider } from "./Contexts/RedirectContext";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import MWL_2C from "./Components/Pages/Splits/MWL_2C";
import MWL_2D from "./Components/Pages/Splits/MWL_2D";
import MWL_2E from "./Components/Pages/Splits/MWL_2E";

const MWL_ADV_V2 = lazy(() =>
  import("./Components/Pages/Advertorials/MWL_ADV_V2")
);
const MWL_ADV_V3 = lazy(() =>
  import("./Components/Pages/Advertorials/MWL_ADV_V3")
);

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <Suspense fallback="Loading...">
              <BrowserRouter>
                <Route exact path="/" component={HomePage} />
                <Route exact path={["/MWL_V1"]} component={MWL_V1} />
                <Route exact path={["/MWL_V2"]} component={MWL_V2} />
                <Route exact path={["/MWL_V2B"]} component={MWL_V2B} />
                <Route exact path={["/MWL_V4"]} component={MWL_V4} />
                <Route exact path={["/MWL_ADV_V1"]} component={MWL_ADV_V1} />
                <Route exact path={["/MWL_ADV_V2"]} component={MWL_ADV_V2} />
                <Route exact path={["/MWL_ADV_V3"]} component={MWL_ADV_V3} />
                <Route exact path={"/signature"} component={Signature} />
                <Route exact path={["/unqualified"]} component={Unqualified} />
                <Route exact path={["/fbpixel"]} component={FbPixel} />
                <Route exact path={["/404"]} component={NotFound} />
                <Route exact path={["/followup"]} component={Followup} />
                <Route exact path={["/thankyou"]} component={Thankyou} />
                <Route exact path={["/analyse"]} component={Analyse} />
                <Route exact path={["/MWL_2D"]} component={MWL_2D} />
                <Route exact path={["/MWL_2E"]} component={MWL_2E} />
                <Route
                  exact
                  path={["/privacypolicy"]}
                  component={PrivacyPolicy}
                />
                <Route exact path={["/terms"]} component={Terms} />
                <Route exact path={["/MWL_2C"]} component={MWL_2C} />
              </BrowserRouter>
            </Suspense>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;

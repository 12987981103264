import React, { useEffect, useState } from "react";
import "../../../assets/MWL_V1/css/MWL_V1.scss";
import Header from "../../Includes/Layouts/MWL_V1/Header";
import Footer from "../../Includes/Layouts/MWL_V1/Footer";
import FormMWL_V1 from "../../Forms/FormMWL_V1";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import RingbaApi from '../../../Utility/RingbaApi';
import OfflineSpanishButtonLink from '../../../Utility/OfflineSpanishButtonLink';
import RingbaPhoneNumber from '../../../Utility/RingbaPhoneNumber';


const MWL_V1 = () => {

  const campaign_name = 'english';
  const { response } = RingbaApi(campaign_name);
  let is_online = 'online';
  let redirect = response.redirect_path;

  const query = new URLSearchParams(window.location.search);
  const utmName = query.get('utm_campaign');
  const [buttonRedirect, setButtonRedirect] = useState();



  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);
  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumber(utmName);




  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MWL_V1" />
      <div className="mywillmyway">
        <Header />
        <section className="SECT">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12 col-sm-12 pad0" id="formpartsec">
                <h1 className="d-block d-sm-none d-md-none d-lg-none">Protect<span className="headspan"> Your Assets </span>And Loved Ones Today!</h1>
                <h2 className="d-block d-sm-none d-md-none d-lg-none">Get Your Will Written From <span className="text-decoration-line-through">£149.99</span> <span className="greenspan mx-1 text-white fw-bold"> £19.99 </span> </h2>
                <div className="form_part">
                  <h1 className="d-none d-sm-block d-md-block d-lg-block">Protect<span className="headspan"> Your Assets </span>And Loved Ones Today!</h1>
                  <h2 className="d-none d-sm-block d-md-block d-lg-block">Get Your Will Written From <span className="text-decoration-line-through"> £149.99 </span> <span className="greenspan px-3 mx-2 text-white fw-bold"> £19.99 </span> </h2>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-sm-none d-md-none d-block">
                    <span className="start-but" id="start-but">Start<br /><img src="/assets/MWL_V1/img/arrow-b.png" alt="" /></span>
                  </div>
                  <div className="clearfix"></div>
                  <FormMWL_V1 formattedPhoneNumber={formattedPhoneNumber} phoneNumber={phoneNumber} />
                </div>
                <div className="col-lg-10 offset-lg-1 col-md-10 offset-md-1 col-sm-12 col-12 my-3">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row m-0 p-0">
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 texter">
                        <ul className="p-0">
                          <li className="tiksec">Thousands of Wills Already Written</li>
                        </ul>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 texter">
                        <ul className="p-0">
                          <li className="tiksec">Expert Agents at Your Fingertips</li>
                        </ul>
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-12 col-12 texter">
                        <ul className="p-0">
                          <li className="tiksec">Professionally Written and Legally Binding</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </section>
        <section className="fut text-center text-lg-left text-white">
          <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h2 className="text-center">Easy 3 Step Process</h2>
              <div className="futsection col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row m-0">
                  <div className="firstsec col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row m-0">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12 p-0">
                          <h3>1</h3>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                          <p>Fill in our online form to get the ball rolling. This will take less than a minute!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="secondsec col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12 p-0">
                          <h3>2</h3>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                          <p>Our in house experts will prepare your Will to meet all of your requirements.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="thirdsec col-lg-4 col-md-4 col-sm-12 col-12 p-0">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-lg-2 col-md-2 col-sm-12 col-12 p-0">
                          <h3>3</h3>
                        </div>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12">
                          <p>Sit back and relax knowing your loved ones are looked after!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default MWL_V1;

import React from 'react';
import { Link } from 'react-router-dom';
const AdvFooter = () => {
  return (
    <>
      {/* <!-- footer start --> */}
      <footer className="text-center py-5 mt-3">
        <div className="container">
          <div className="col-lg-12">
            <div className="col-lg-12">
              <div className="offset-lg-2 col-lg-8">
                <div className="row">
                  <div className="col-lg-8">
                    <p className="mt-2 text-white">Get involved with My Will My Way on Social Media</p>
                  </div>
                  <div className="col-lg-4">
                    <img src="/assets/MWL_ADV_V1/img/facebook-icon.png" alt="" />
                    <img src="/assets/MWL_ADV_V1/img/twitter-icon.png" alt="" />
                    <img src="/assets/MWL_ADV_V1/img/linkedin-icon.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <ul className="text-center p-0">
              <li>
                <Link className="rp_clk text-white text-decoration-none" to="terms" target="_blank" rel="noopener noreferrer" >
                  Terms and Conditions
                </Link>
              </li>
              <li>|</li>
              <li>
                <Link className="rp_clk text-white text-decoration-none" to="privacypolicy" target="_blank" rel="noopener noreferrer" >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
      {/* <!-- footer end -->       */}
    </>
  )
}
export default AdvFooter;

import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import icosafe from "../../../../assets/MWL_V2B/img/ico-safe.png"
const BasicQuestionnaireOne = ({
  className,
  slideChange,
  textHeading,
  validation
}) => {
  const nextSlide = async (e) => {
    changeActiveStatus(e);
    document.getElementById("start-but").classList.add('hide');
    setTimeout(() => {
      slideChange(e);
    }, 50);
  };

  const [activestatus, setActivestatus] = useState(0);
  const changeActiveStatus = (e) => {
    if (e.target.value == 1) {
      setActivestatus(1);

    } else if (e.target.value == 2) {
      setActivestatus(2);
    }
  }

  return (
    <>
      <div id="slide1" className={`col-12 top_minus_1 ${className}`}>
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row">
              <div className="form-group">
                <label className="text-center mb-1">{textHeading}</label>
                <RadioButton
                  tabIndex="1"
                  value="1"
                  name="born_before"
                  id="born_before_yes"
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="Yes"
                  labelClassName={(activestatus == 1 ? "active " : "") + "icon_option smoker3 m_family load sctop next01"}
                  autoComplete="off"
                  onClick={nextSlide}
                  validation={validation()}
                />                
                <RadioButton
                  tabIndex="2"
                  value="2"
                  name="born_before"
                  id="born_before_no"
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="No"
                  labelClassName={(activestatus == 2 ? "active " : "") + "icon_option smoker3 m_family load mobload sctop next01"}
                  autoComplete="off"
                  onClick={nextSlide}
                  validation={validation()}
                />
              </div>
              <span id="fullDOB_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="text-center safe-text">
            <img src={icosafe} alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;

import React, { useState, useRef } from "react";
import TextField from '../../../UI/TextField';
import ProgressBar from "./ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import arrow from "../../../../assets/MWL_V2B/img/arrow.png";
import icosafe from "../../../../assets/MWL_V2B/img/ico-safe.png"

const BasicDetails = ({
  className,
  textHeading,
  setError,
  slidePreChange,
  validation,
  validationMsg,
  basic_onchangehandle,
  BasicDetailsSubmit,
  reff,
}) => {

  const [activestatus, setActivestatus] = useState(0);
  const [showWillForError, setwillForError] = useState('hide');
  const [willFor, setWillFor] = useState(false);

  const scrollfrmv2div = useRef(null);

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length == 0)
      setError(e.target.name, {
        type: "manual",
        message: "Your Name is Invalid. Please Recheck",
      });
    return false;
  };

  const nextSlide = async (e) => {
    BasicDetailsSubmit();
  }

  const changeActiveStatus = (e) => {
    if (e.target.value == '14') {
      setActivestatus(1);
    } else if (e.target.value == '15') {
      setActivestatus(2);
    } else if (e.target.value == '16') {
      setActivestatus(3);
    }
  }

  const WillValidation = (e) => {
    changeActiveStatus(e);
    if (e.target.checked === true) {
      setwillForError('hide');
      setWillFor(true);
    }
  }

  return (
    <>
      <div id="slide6" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="70" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 px-3">
                <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
                  <div className="row">
                    <div className="form-group">
                      <label className="text-center mb-1">{textHeading}</label>
                      
                      <span id="fullDOB_err" className="error_msg error"></span>
                    </div>
                    <div className="form-group">
                      <i className="validate"></i>
                      <div className=" form-group" ref={(el) => (reff.current['txtFName'] = el)}>
                        <TextField
                          type="text"
                          className="form-control  hero-input"
                          placeholder="First Name"
                          name="txtFName"
                          id="txtFName"
                          validation={validation({
                            required: "Please Enter First Name",
                            minLength: {
                              value: 3,
                              message: "Please Enter Valid First Name",
                            },
                            pattern: {
                              value: /^[a-zA-Z\s]+$/,
                              message: "Your Name is Invalid. Please Recheck",
                            },
                          })}
                          validationMsg={
                            validationMsg.txtFName && validationMsg.txtFName.message
                          }
                          onChange={basic_onchangehandle}
                          onBlur={namevalidate}
                        />
                      </div>
                      <div className=" form-group" ref={(el) => (reff.current['txtLName'] = el)}>
                        <TextField
                          type="text"
                          className="form-control  hero-input"
                          placeholder="Last Name"
                          name="txtLName"
                          id="txtLName"
                          validation={validation({
                            required: "Please Enter Last Name",
                            minLength: {
                              value: 3,
                              message: "Please Enter Valid Last Name",
                            },
                            pattern: {
                              value: /^[a-zA-Z\s]+$/,
                              message: "Your Name is Invalid. Please Recheck",
                            },
                          })}
                          validationMsg={
                            validationMsg.txtLName && validationMsg.txtLName.message
                          }
                          onChange={basic_onchangehandle}
                          onBlur={namevalidate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <input type="button" id="next06" name="next06" className="next-but btn my-bts flasher2 sctop1 next06" defaultValue="Continue >>" autoComplete="off" onClick={nextSlide} />
          <div className="col-12 text-center mt-1">
            <a id="back5" name="back5" className="back_btn sctop1" onClick={slidePreChange}><img src={arrow} alt="" />  Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src={icosafe} alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicDetails;

import React, { useState } from "react";
import ProgressBar from "../../Layouts/MWL_V2/ProgressBar";
import RadioButton from "../../../UI/RadioButton";

const BasicQuestionnaireEight = ({
  className,
  slideChange,
  textHeading,
  slidePreChange,
  validation
}) => {
  const nextSlide = async (e) => {
    changeActiveStatus(e);
    slideChange(e);
  };

  const preSlide = async (e) => {
    document.getElementById("start-but").classList.remove('hide');
    slidePreChange(e);
  };

  const [activestatus, setActivestatus] = useState(0);
  const changeActiveStatus = (e) => {
    if (e.target.value == 14) {
      setActivestatus(14);

    } else if (e.target.value == 15) {
      setActivestatus(15);
    }
  }

  return (
    <>
      <div id="slide2" className={`col-12 top_minus_1 ${className}`}>
      <ProgressBar value="40" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row">
              <div className="form-group">
              <label className="text-center mb-1" >{textHeading}</label>
                      <RadioButton
                        tabIndex="1"
                        value="14"
                        id="myself"
                        name="will_for"
                        className="radio-button custom-radio-button"
                        labelName="Myself"
                        labelClassName={(activestatus == 14 ? "active " : "") + "icon_option smoker3 m_family load sctop next04"}
                        validation={validation()}
                        onClick={nextSlide}
                      />
                      <RadioButton
                        tabIndex="2"
                        value="15"
                        id="mypartner"
                        name="will_for"
                        className="radio-button custom-radio-button"
                        labelName="Myself & Partner"
                        labelClassName={(activestatus == 15 ? "active " : "") + "icon_option smoker3 m_family load sctop next04"}
                        validation={validation()}
                        onClick={nextSlide}
                      />
                      {/* <RadioButton
                        tabIndex="3"
                        value="16"
                        id="singlewills"
                        name="will_for"
                        className="radio-button custom-radio-button"
                        labelName="(2) Single Wills"
                        labelClassName={activestatus == 3 ? "icon_option smoker3 m_family load mobload sctop active" :
                          "icon_option smoker3 m_family load mobload sctop"
                        }
                        validation={validation()}
                        onClick={nextSlide}
                      /> */}
              </div>
              <span id="cover_for_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="col-12 text-center mt-1">
            <a id="back2" name="back18" className="back_btn sctop1"  onClick={preSlide}><img src="/assets/MWL_V2/img/arrow.png" alt=""/> Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src="/assets/MWL_V2/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireEight;

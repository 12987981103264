import React, { useState, useRef, useEffect, useContext } from "react";
import ProgressBar from "./ProgressBar";
import CustomCheckBox from "../../../UI/CustomCheckBox";
import { useUserClicks } from "../../../../Hooks/useUserClicks";
import { UUID } from '../../../../Utility/UUID';
import useJourneyStats from '../../../../Hooks/useJourneyStats';
import confetti from 'canvas-confetti';
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import GtmDataLayer from "../../GtmDataLayer";
import PixelTrigger from "../../../../Utility/PixelTrigger";
import RadioButton from "../../../UI/RadioButton";
import QueryStringContext from "../../../../Contexts/QueryString";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";



const AgentCall = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  setLifeinsuranceList,
  phoneNumber,
  splitName,
  validation,
  formSubmit,
  getValues,
  // setCurrentSlide,
  slideValues,
}) => {
  const [lifeinsuranceList] = useState([]);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const { saveDataIngestion } = useDataIngestion();
  const [showError, setError] = useState('hide');
  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };
  const saveJournyStats = useJourneyStats();
  const tyPixelTrigger = PixelTrigger();

  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  // const journeyStatus = async (values) => {
  //   const { slide_type, slide_question, slide_value, type } = values;

  //   await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  // };

  const scrollSlide11 = useRef(null);

  useEffect(() => {
    if (slideValues.question === "homeowner" && slideValues.value === "13") {
      setTimeout(() => {
        // confetti({
        //   particleCount: 100,
        //   spread: 100,
        //   origin: { y: 0.9 },
        // });
      }, 0);
    }
  }, [slideValues])

  const handleOptionClick = async (option, e) => {
    changeActiveStatus(e)
    if (option == 1) {
      GtmDataLayer({
        question: "ringba call",
        answer: "Completed",
      });
      saveUser('CALL_CLICK', option);
      saveJournyStats(uuid, 'button', 'CALL_CLICK', '', 'click', splitName);
      tyPixelTrigger({
        splitName: splitName,
      });
      const { born_before, marital_status, children, homeowner,will_yes,call_no } = getValues();
      const formData = {
        born_before,
        marital_status,
        children,
        homeowner,
        will_yes,
        call_no
      };
        formData.lstSalutation = '';
        formData.txtFName = '';
        formData.txtLName = '';
        formData.txtEmail = '';
        formData.txtPhone = '';
        formData.txtPostCode = '';
        formData.preferredTimeForCall = '';
        formData.call_no= 25;
      try {
        const response = await saveDataIngestion(
          visitorParameters?.visitor_parameters,
          formData,
          "split_form_submit",
          splitName,
          queryString,
          visitorParameters?.data
        );
        console.log({ response }, response.data, response.data.status);
        if (response.status !== 200) return;

      } catch (error) {
        console.log({ error });
        return;
      }
      return;
    }
    else {
      slideChange(e);
    }
    // saveJournyStats({
    //   slide_type: 'button',
    //   slide_question: 'CALL_CLICK',
    //   slide_value: '',
    //   type: 'click',
    // });
  }
  const [activestatus, setActivestatus] = useState(0);
  const changeActiveStatus = (e) => {
    if (e.target.value == 1) {
      setActivestatus(1);

    } else if (e.target.value == 2) {
      setActivestatus(2);
    }
  }

  return (
    <>
      <div ref={scrollSlide11} id="slide11" className={`col-12 ${className}`}>
        <ProgressBar value="90" />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 px-4 bord text-center">
            <p className="mx-md-5" style={{ fontSize: "16px" }}>
              <span>To get the best possible price, we have live agents standing by to help assist you </span></p>
            {/* <input type="button" id="next11" name="next11" className="next-but btn flasher my-bts sctop1 next03" value="Call Now" autoComplete="off" onClick={() => handleOptionClick(1)}  href={`tel: ${phoneNumber}`} /> */}
            <div className="form-group">
              <div className="text-center mb-1" style={{ fontSize: "18px" }}><b>Are you available to speak with a live agent now ?</b> </div>
              <span>
                <a
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="Yes"
                  value="25"
                  name='call_no'
                  labelClassName='next-but btn flasher my-bts sctop1 next03 call-btn'
                  onClick={(e) => handleOptionClick(1, e)}  
                  ref={validation()}
                  href={`tel: ${phoneNumber}`}>
                  <label className="next-but btn my-bts flasher2 sctop1">Yes</label>
                </a>
                <RadioButton
                  tabIndex="2"
                  value="26"
                  id="call_no"
                  name="call_no"
                  className="radio-button custom-radio-button sctop next-but"
                  labelName="No"
                  validation={validation()}
                  labelClassName="next-but btn my-bts flasher2 sctop1"
                  // onClick={onRedirect}
                  onClick={(e) => handleOptionClick('', e)}
                />
              </span>
            </div>

            <div className="col-12 text-center mt-1">
              <a id="back9" name="back11" value='1' className="back_btn sctop" onClick={slidePreChange}><img src="/assets/MWL_V1/img/arrow.png" alt="" />  Previous </a>
            </div>
            <div className="text-center safe-text">
              <img src="/assets/MWL_V1/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default AgentCall;

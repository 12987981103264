import React, { useState, useRef, useEffect } from "react";
import ProgressBar from "./ProgressBar";
import CustomCheckBox from "../../../UI/CustomCheckBox";
import { useUserClicks } from "../../../../Hooks/useUserClicks";
import { UUID } from '../../../../Utility/UUID';
import useJourneyStats from '../../../../Hooks/useJourneyStats';
import confetti from 'canvas-confetti';
import GtmDataLayer from "../../GtmDataLayer";
import PixelTrigger from "../../../../Utility/PixelTrigger";
import RadioButton from "../../../UI/RadioButton";
import Nomodal from "./Nomodal";

const CallOption = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  setLifeinsuranceList,
  phoneNumber,
  splitName,
  formSubmit,
  validation,
  // setCurrentSlide,
  slideValues,
}) => {
  const [lifeinsuranceList] = useState([]);
  const [showError, setError] = useState('hide');
  const { saveUserClicks } = useUserClicks();
  const [modalClass,SetModalClass] = useState(false);
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };
  const saveJournyStats = useJourneyStats();
  const tyPixelTrigger = PixelTrigger();

  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  // const journeyStatus = async (values) => {
  //   const { slide_type, slide_question, slide_value, type } = values;

  //   await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  // };

  const scrollSlide11 = useRef(null);

  const handleOptionClick = (e) => {

    localStorage.setItem('will_yes',e.target.name);
    if(e.target.value == 24){
      SetModalClass(true);
    }
    else{
      slideChange(e);
    }
    

    // GtmDataLayer({
    //   question: "ringba call",
    //   answer: "Completed",
    // });
    // saveUser('CALL_CLICK', option);
    // saveJournyStats(uuid,'button','CALL_CLICK','','click',splitName);
    // tyPixelTrigger({
    //   splitName : splitName,
    // });
    // saveJournyStats({
    //   slide_type: 'button',
    //   slide_question: 'CALL_CLICK',
    //   slide_value: '',
    //   type: 'click',
    // });
  }

  return (
    <>
      <div ref={scrollSlide11} id="slide11" className={`col-12 ${className}`}>
        <ProgressBar value="80" />
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 px-4 bord text-center">
            <p className="mx-md-5" style={{fontSize:"16px"}}>
              <span style={{fontSize:"25px"}}>Thank you for your interest ! 🤩🤩🥳🥳🥳 </span><br />
              You may qualify for an amazing life cover opportunity<br />
              UK Residents aged 50 - 80 may qualify for lump sum life cover up to <u><b>£35,000</b></u>, With this life cover we can also offer a FREE WILL when signing up for a policy!
            </p>
            {/* <input type="button" id="next11" name="next11" className="next-but btn flasher my-bts sctop1 next03" value="Call Now" autoComplete="off" onClick={() => handleOptionClick(1)}  href={`tel: ${phoneNumber}`} /> */}
            <div className="form-group">
            <div className="text-center mb-2" style={{fontSize:"16px"}}><b>Are you interested in lump sum Life Cover + A FREE Will ?</b></div>
            <span>
                  <RadioButton
                        tabIndex="1"
                        value="23"
                        id="will_yes"
                        name="will_yes"
                        className="radio-button custom-radio-button sctop next-but"
                        labelName="Yes"
                        labelClassName="next-but btn my-bts flasher2 sctop1"
                        onClick={handleOptionClick}
                        validation={validation()}
                      />
                      <RadioButton
                        tabIndex="2"
                        value="24"
                        id="will_no"
                        name="will_yes"
                        className="radio-button custom-radio-button sctop next-but"
                        labelName="No"
                        labelClassName="next-but btn my-bts flasher2 sctop1"
                        // onClick={onRedirect}
                        validation={validation()}
                        onClick={handleOptionClick}
                      />
                            </span>
                            </div>

            <div className="col-12 text-center mt-1">
              <a id="back9" name="back10" className="back_btn sctop" value='1' onClick={slidePreChange}><img src="/assets/MWL_V1/img/arrow.png" alt=""/>  Previous </a>
            </div>
            <div className="text-center safe-text">
              <img src="/assets/MWL_V1/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
            </div>
          </div>
        </div>
            <Nomodal 
             modalClass={modalClass}
             SetModalClass={SetModalClass}
            />
      </div>
    </>
  );
};

export default CallOption;

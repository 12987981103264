import React, { useState, useRef } from "react";
import ProgressBar from "./ProgressBar";
import CustomCheckBox from "../../../UI/CustomCheckBox";

const BasicQuestionnaireSix = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  updateCheckedList,
  formSubmit
}) => {
  const [whatIsImportantList] = useState([]);
  const [showError, setError] = useState("hide");
  const nextSlide = async (e) => {
    if (checkBoxValidation()) {
      slideChange(e);
    }
  };

  const scrollSlide3 = useRef(null);

  const onSelect = (e) => {
    if (e.target.checked === true) {
      whatIsImportantList.push({ value: e.target.value });
      checkBoxValidation();
    } else {
      whatIsImportantList.pop({ value: e.target.value });
      checkBoxValidation();
    }
    updateCheckedList(whatIsImportantList);
  };

  const checkBoxValidation = (e) => {
    if (whatIsImportantList.length > 0) {
      setError("hide");
      return true;
    }
    window.scrollTo({
      top: scrollSlide3.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    setError("show");
    return false;
  };
  return (
    <>
      <div
        ref={scrollSlide3}
        id="what_is_important_slide"
        className={`col-12 ${className}`}
      >
        <ProgressBar value="45" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <label>{textHeading}</label>
            <p className="text-center paragrap">
              Please check all answers that apply
            </p>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 col-12">
                  <ul className="options">
                    <li className="popupLink mt-3">
                      <CustomCheckBox
                        value="19"
                        id="whatIsImportant1"
                        labelText="Cover end of life expenses"
                        name="whatIsImportant1"
                        labelClassName="defaultchk"
                        dataId="whatIsImportant1"
                        onChange={onSelect}
                      />
                    </li>
                    <li className="popupLink">
                      <CustomCheckBox
                        value="20"
                        id="whatIsImportant2"
                        labelText="Leave financial security for my family"
                        name="whatIsImportant2"
                        labelClassName="defaultchk"
                        dataId="whatIsImportant2"
                        onChange={onSelect}
                      />
                    </li>
                    <li className="popupLink">
                      <CustomCheckBox
                        value="21"
                        id="whatIsImportant3"
                        labelText="Leave money for an organization, such as a personal business or charity"
                        name="whatIsImportant3"
                        labelClassName="defaultchk"
                        dataId="whatIsImportant3"
                        onChange={onSelect}
                      />
                    </li>
                    <li className="popupLink">
                      <CustomCheckBox
                        value="22"
                        id="whatIsImportant4"
                        labelText="Other"
                        name="whatIsImportant4"
                        labelClassName="defaultchk"
                        dataId="whatIsImportant4"
                        onChange={onSelect}
                      />
                    </li>
                  </ul>
                  <span
                    id="fullDOB_err"
                    className={`error_msg error ${showError}`}
                  >
                    Please Select atleast one.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <input
            type="button"
            id="what_is_important"
            name="what_is_important"
            className="next-but btn flasher my-bts sctop1 what_is_important"
            defaultValue="Continue >>"
            autoComplete="off"
            onClick={nextSlide}
          />
          <div className="col-12 text-center mt-1">
            <a
              id="back4"
              className="back_btn sctop1"
              name="back4"
              onClick={slidePreChange}
            >
              <img src="/assets/MWL_V2/img/arrow.png" alt="" /> Previous{" "}
            </a>
          </div>
          <div className="text-center safe-text">
            <img
              src="/assets/MWL_V2/img/ico-safe.png"
              alt="Safe, Secure &amp; Confidential"
              width="12"
              height="15"
            />
            Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireSix;

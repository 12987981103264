export const Salutation = [
  {
    label: "Title",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Dr.",
    value: "Dr",
  },
  {
    label: "Prof.",
    value: "Prof",
  },
];

export const DobDay = [{ label: "Day", value: "" }];
const startDay = 1;
const endDay = 31;
for (var i = startDay; i <= endDay; i++) {
  var objDobDay = {};
  objDobDay["label"] = i;
  objDobDay["value"] = i;
  DobDay.push(objDobDay);
}

export const DobMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DobYear = [{ label: "Year", value: "" }];
const startYear = 1992;
const endYear = 1910;
for (var k = startYear; k >= endYear; k--) {
  var objDob = {};
  objDob["label"] = k;
  objDob["value"] = k;
  DobYear.push(objDob);
}
const BannerYearAdv1 = [];
const start = 1926;
const end = 1989;
for (var i = start; i <= end; i++) {
  if (start === i) {
    BannerYearAdv1.push([-i]);
  } else {
    BannerYearAdv1.push([i]);
  }
}
export { BannerYearAdv1 };
export const signCheckText = `By signing and by pressing “Submit” below, you agree to Check for SSB Group <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and that we may use your signature on the <a style="cursor:pointer; color:#00aab1 !important;" href=""> Letter of Authority</a> that we send to your bank.  A copy of the <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and <a style="cursor:pointer; color:#00aab1 !important" href="">Letter of Authority</a> will be sent to you via email.
`;

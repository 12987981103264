import React from "react";
import AnchorAdv from "../../../UI/AnchorAdv";
import FbViewPixel from "../../../../Utility/FbViewPixel";
const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      {/* <!-- header start --> */}
      <header>
        <div className="container">
          <div className="row">
            <AnchorAdv
              dataId="SITE_lOGO"
              className="col-lg-12 col-md-12 col-sm-12 col-12 text-center py-3"
            >
              <img src="/assets/MWL_ADV_V1/img/logo.png" alt="" />
            </AnchorAdv>
          </div>
        </div>
      </header>
      {/* <!-- header end --> */}
    </>
  );
};
export default AdvHeader;

import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Telephone from "../../../UI/Telephone";
import Email from "../../../UI/Email";
import ProgressBar from "../../Layouts/MWL_V1/ProgressBar";
import InputButton from "../../../UI/InputButton";

const ContactDetails = ({
  className,
  slidePreChange,
  validation,
  validationMsg,
  basic_onchangehandle,
  emailValidCheck,
  phoneValidCheck,
  disabled,
  reff,
  formSubmit
}) => {

  return (
    <>
      <div id="slide7" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value="80" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 form_cont">
          <div className="col-lg-12 col-md-12 col-sm-12 form-group">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <p className="text-center para"><strong>What is the best way for our friendly team to contact you?</strong></p>
                <div className=" form-group" ref={(el) => (reff.current['txtPhone'] = el)}>
                  <Telephone
                    name="txtPhone"
                    id="txtPhone"
                    className="form-control nme_inpu"
                    placeholder="Phone Number"
                    maxlength="11"
                    onBlur={phoneValidCheck}
                    onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
                    validation={validation({ required: true })}
                    validationMsg={
                      validationMsg.txtPhone && "Please Enter Valid Phone Number"
                    }
                    onChange={basic_onchangehandle}
                  ></Telephone>
                </div>
              </div>
              <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 mt-1 dmy">
                <div className=" form-group mb-1" ref={(el) => (reff.current['txtEmail'] = el)}>
                  <Email
                    name="txtEmail"
                    id="txtEmail"
                    className="form-control"
                    placeholder="Email Address"
                    onBlur={emailValidCheck}
                    validation={validation({
                      required: "Please Enter Valid Email Address",
                      pattern: {
                        value:
                          /^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
                        message: "Invalid Email Address",
                      },
                    })}
                    validationMsg={
                      validationMsg.txtEmail && validationMsg.txtEmail.message
                    }
                    onChange={basic_onchangehandle}
                  >
                  </Email>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
                {/* <a href="#" className="next-but btn my-bt sctop1" style={{ color: "#fff !important" }} id="btn_submit_claim" onClick={formSubmit}>Get My Free Quote &gt;&gt;</a> */}
                <InputButton
                  style={{ float: "none" }}
                  name="submit_form"
                  className="next-but btn my-bt sctop1 next07"
                  value="Get My Free Quote &gt;&gt;"
                  btnType="button"
                  onClick={formSubmit}
                  id="btn_submit_claim"
                  disabled={disabled}
                />
                 <br/>

                 <br/>
    
                <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                  <div className="col-lg-8 offset-lg-2 col-12 authorise text-center">
                    <p>You will receive a call from our specialist Will advisors if we feel we can help you based on the information you have provided.</p>
                  </div>
                </div>
                <div className="text-center safe-text">
                  <img src="/assets/MWL_V1/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <div className="offset-lg-2 col-lg-8 col-lg-offset-2 terms mob-0">
                  <div className="consent text-center">
                    <p>By submitting your enquiry, you agree to our <Link className="rp_clk" to="privacypolicy" target="_blank" rel="noopener noreferrer" >Privacy Policy</Link> and agree we may use the contact details provided to contact you about your quote.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactDetails;

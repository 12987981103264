import React, {
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
  } from "react";
  import { useForm } from "react-hook-form";
  import { useHistory } from "react-router-dom";
  import QueryStringContext from "../../Contexts/QueryString";
  import VisitorParamsContext from "../../Contexts/VisitorParams";
  import { useDataIngestion } from "../../Hooks/useDataIngestion";
  import { useEmailValidation } from "../../Hooks/useEmailValidation";
  import { usePhoneValidation } from "../../Hooks/usePhoneValidation";
  import GtmDataLayer from "../Includes/GtmDataLayer";
  import BasicDetails from "../Includes/Layouts/MWL_V2B/BasicDetails";
  import BasicQuestionnaireFive from "../Includes/Layouts/MWL_V2B/BasicQuestionnaireFive";
  import BasicQuestionnaireFour from "../Includes/Layouts/MWL_V2B/BasicQuestionnaireFour";
  import BasicQuestionnaireOne from "../Includes/Layouts/MWL_V2B/BasicQuestionnaireOne";
  import BasicQuestionnaireTwo from "../Includes/Layouts/MWL_V2B/BasicQuestionnaireTwo";
  import ContactDetails from "../Includes/Layouts/MWL_V2B/ContactDetails";
  import DOB from "../Includes/Layouts/MWL_V2B/DOB";
  import PostCode from "../Includes/Layouts/MWL_V2B/PostCode";
  import BasicQuestionnaireSix from "../Includes/Layouts/MWL_V2B/BasicQuestionnaireSix";
  import BasicQuestionnaireThree from "../Includes/Layouts/MWL_V2B/BasicQuestionnaireThree";
  import CallOption from "../Includes/Layouts/MWL_V2B/CallOption";
  import loader_gif from '../../assets/MWL_V2B/img/loader-gif.gif';
  import confetti from 'canvas-confetti';
import BasicQuestionnaireEight from "../Includes/Layouts/MWL_V2B/BasicQuestionnaireEight";
  
  const FormMWL_V2B = ({phoneNumber,formattedPhoneNumber}) => {
    const history = useHistory();
    const field = "pid";
    const scrollfrmv2div = useRef(null);
    const { saveDataIngestion, isLoading } = useDataIngestion();
  
    const { visitorParameters } = useContext(VisitorParamsContext);
  
    const { queryString } = useContext(QueryStringContext);
    const [activateCallBtn, setActivateCallBtn] = useState(false);
    const [loading, setLoading] = useState(0);
    // const [currentSlide, setCurrentSlide] = useState(null);
    const [slideValues, setSlideValues] = useState({
      question:null,
      value:null
    });
  
  
    const initialState = {
      showSlide1: "show",
      showSlide2: "hide",
      showSlide3: "hide",
      showSlide4: "hide",
      showSlide4_5: "hide",
      showSlide5: "hide",
      showSlide5_5 : "hide",
      showSlide6: "hide",
      showSlide7: "hide",
      showSlide8: "hide",
      showSlide9: "hide",
      showSlide10: "hide",
      showSlide11: "hide",
    };
    const [seekingWillList, checkSeekingWillList] = useState(null);
    // const [lifeinsuranceList, setLifeinsuranceList] = useState(null);
    const [whatIsImportantList, setWhatIsImportantList] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const splitName = "MWL_V2B";
    const FormReducer = (state, action) => {
      switch (action.type) {
        case "showSlide": {
          const slide = action.payload.clickedSlide.slide;
          const value = action.payload.clickedSlide.value;
          // console.log("next", slide, value);
  
          if (slide === "born_before") {
            GtmDataLayer({
              question: "born year",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide1: "hide",
              showSlide2: "show",
            };
          } else if (slide === "marital_status") {
            GtmDataLayer({
              question: "martial status",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide2: "hide",
              showSlide3: "show",
            };
          } else if (slide === "children") {
            GtmDataLayer({
              question: "have children",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide3: "hide",
              showSlide4: "show",
            };
          } else if (slide === "homeowner") {
            if (value == "13") {
              GtmDataLayer({
                question: "rented",
                answer: "Completed",
              });
              return {
                ...state,
                showSlide4: "hide",
              showSlide11: "show",
              };
            } else {
              GtmDataLayer({
                question: "home owner",
                answer: "Completed",
              });
            }
  
  
            return {
              ...state,
              showSlide4: "hide",
              showSlide5: "show",
            };
          }else if (slide === "what_is_important") {
            GtmDataLayer({
              question: "What is most important?",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide4_5: "hide",
              showSlide5: "show",
            };
          } else if (slide === "next05") {
            GtmDataLayer({
              question: "postcode",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide5: "hide",
              showSlide5_5: "show",
            };
          } else if (slide === "will_for") {
            GtmDataLayer({
              question: "personalised quote",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide5_5: "hide",
              showSlide6: "show",
            };
          } 
          else if (slide === "next06") {
            GtmDataLayer({
              question: "personal details",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide6: "hide",
              showSlide7: "show",
            };
          } else if (slide === "next07") {
            GtmDataLayer({
              question: "dob",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide7: "hide",
              showSlide8: "show",
            };
          } else if (slide === "next10") {
            setTimeout(() => {
              setActivateCallBtn(true);
              confetti({
                particleCount: 100,
                spread: 100,
                origin: { y: 0.9 },
              });
            }, 1500);
  
            GtmDataLayer({
              question: "Homeowner?",
              answer: "Completed",
            });
            return {
              ...state,
              showSlide10: "hide",
              showSlide11: "show",
            };
          }else {
            return {
              ...state,
            };
          }
        }
        case "showPreSlide": {
          const slide = action.payload.clickedPreSlide.slide;
  
          if (slide === "back1") {
            return {
              ...state,
              showSlide2: "hide",
              showSlide1: "show",
            };
          } else if (slide === "back2") {
            return {
              ...state,
              showSlide3: "hide",
              showSlide2: "show",
            };
          } else if (slide === "back3") {
            return {
              ...state,
              showSlide4: "hide",
              showSlide3: "show",
            };
          } else if (slide === "back4") {
            if (!!whatIsImportantList?.length) {
              return {
                ...state,
                showSlide5: "hide",
                showSlide4_5: "show",
              };
            }
  
            return {
              ...state,
              showSlide5: "hide",
              showSlide4_5: "hide",
              showSlide4: "show",
            };
          } else if (slide === "back5") {
            return {
              ...state,
              showSlide6: "hide",
              showSlide5_5: "show",
            };
          } else if (slide === "back6") {
            return {
              ...state,
              showSlide7: "hide",
              showSlide6: "show",
            };
          } else if (slide === "back7") {
            return {
              ...state,
              showSlide8: "hide",
              showSlide3: "show",
            };
          }else if (slide === "back8") {
            return {
              ...state,
              showSlide10: "hide",
              showSlide4: "show",
            };
          } else if (slide === "back9") {
            return {
              ...state,
              showSlide11: "hide",
              showSlide10: "show",
            };
          } else if (slide === "back18") {
            return {
              ...state,
              showSlide5_5: "hide",
              showSlide5: "show",
            };
          }  else {
            return {
              ...state,
            };
          }
        }
        default: {
        }
      }
    };
    const splitForm = useRef(null);
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState([]);
    const [clickedPreSlide, setClickedPreSlide] = useState([]);
    const { register, errors, trigger, setError, clearErrors, getValues } =
      useForm({
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
      });
  
      useEffect(() => {
        console.log(slideValues);
      }, [slideValues])
  
    const slideChange = (e) => {
      setClickedSlide({ slide: e.target.name, value: e.target.value });
      setSlideValues({
        question: e.target.name,
        value: e.target.value
      })
      window.scrollTo({
        top: scrollfrmv2div.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    };
  
    useEffect(() => {
      if (clickedSlide) {
        dispatch({ type: "showSlide", payload: { clickedSlide } });
      }
    }, [clickedSlide]);
  
    const slidePreChange = (e) => {
      setClickedPreSlide({ slide: e.target.name });
      setTimeout(() => {
        window.scrollTo({
          top: scrollfrmv2div.current.offsetTop,
          left: 0,
          behavior: "smooth",
        });
      }, 50);
    };
  
    useEffect(() => {
      if (clickedPreSlide) {
        dispatch({ type: "showPreSlide", payload: { clickedPreSlide } });
      }
    }, [clickedPreSlide]);
  
    useEffect(() => {
      GtmDataLayer({
        question: "Page Opened",
        answer: "Completed",
      });
    }, []);
    useEffect(() => {
      // 1 = enable
      // 2 = disable
      if (state.showSlide11 === 'show') {
        setLoading(1);
        setTimeout(() => {
          setLoading(2);
        }, 1500);
      }
    }, [state.showSlide11]);
  
    const basic_onchangehandle = async (e) => {
      trigger(e.target.name);
    };
  
    const { emailValidation } = useEmailValidation();
    const { phoneValidation } = usePhoneValidation();
  
    const namevalidate = async (e) => {
      if (e.target.value.trim() == "" || e.target.value.trim().length == 0)
        setError(e.target.name, {
          type: "manual",
          message: "Your Name is Invalid. Please Recheck",
        });
      return false;
    };
  
    const [emailCheck, setEmailCheck] = useState("hide");
    const emailValidCheck = async () => {
      const values = getValues();
      const email = values["txtEmail"];
      if (email !== "") {
        if (
          /^([\w-\.]+)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
            email
          )
        ) {
          setEmailCheck("show");
          const getEmailValidation = await emailValidation(email);
          if (getEmailValidation.data.status_code === 0) {
            scrollToElement("txtEmail");
            setError("txtEmail", {
              type: "manual",
              message: "Email Already Exist",
            });
            setEmailCheck("hide");
            document.getElementById("btn_submit_claim").disabled = false;
            return 0;
          } else if (getEmailValidation.data.status_code === 2) {
            scrollToElement("txtEmail");
            setError("txtEmail", {
              type: "manual",
              message: "Email is Invalid",
            });
            setEmailCheck("hide");
            document.getElementById("btn_submit_claim").disabled = false;
            return 0;
          } else {
            setEmailCheck("hide");
            return 1;
          }
        } else {
          scrollToElement("txtEmail");
          setError("txtEmail", {
            type: "manual",
            message: "Invalid Email Address",
          });
          document.getElementById("btn_submit_claim").disabled = false;
          return 0;
        }
      } else {
        document.getElementById("btn_submit_claim").disabled = false;
        trigger("txtEmail");
        return 0;
      }
    };
  
    const phoneValidCheck = async () => {
      // return 1; // For testing
      const values = getValues();
      const phone = values["txtPhone"];
      if (phone !== "") {
        const getPhoneValidation = await phoneValidation(phone);
        if (
          getPhoneValidation.data.status_code === 0 ||
          getPhoneValidation.data.status === "Fail"
        ) {
          scrollToElement("txtPhone");
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Working Phone Number",
          });
          document.getElementById("btn_submit_claim").disabled = false;
          return 0;
        } else if (getPhoneValidation.data.status_code === 2) {
          scrollToElement("txtPhone");
          setError("txtPhone", {
            type: "manual",
            message: "Phone Number Already Exist",
          });
          document.getElementById("btn_submit_claim").disabled = false;
          return 0;
        } else if (getPhoneValidation.data.status_code === 3) {
          scrollToElement("txtPhone");
          setError("txtPhone", {
            type: "manual",
            message: "Please Enter Valid Phone Number",
          });
          document.getElementById("btn_submit_claim").disabled = false;
          return 0;
        } else {
          return 1;
        }
      }
    };
  
    const scrollRef = useRef([]);
    const scrollToElement = (element) => {
      scrollRef.current[element]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    };
  
    const BasicDetailsSubmit = async () => {
      let errorFlag = 0;
      const values = getValues();
      const form = splitForm.current;
      const formData = values;
  
      const txtFName = values["txtFName"];
      const txtLName = values["txtLName"];
  
      let txtFNameResult;
      let txtLNameResult;
  
      // First Name
      txtFNameResult = await trigger("txtFName");
      if (txtFNameResult) {
        if (
          txtFName.trim() == "" ||
          txtFName.trim().length == 0 ||
          txtFName.trim().length < 3
        ) {
          scrollToElement("txtFName");
          setError("txtFName", {
            type: "manual",
            message: "Your Name is Invalid. Please Recheck",
          });
          return false;
        } else {
          txtLNameResult = await trigger("txtLName");
        }
      } else {
        scrollToElement("txtFName");
        return false;
      }
  
      // Lastname
      if (txtLNameResult) {
        if (
          txtLName.trim() == "" ||
          txtLName.trim().length == 0 ||
          txtLName.trim().length < 2
        ) {
          scrollToElement("txtLName");
          setError("txtLName", {
            type: "manual",
            message: "Your Name is Invalid. Please Recheck",
          });
          return false;
        }
      } else {
        scrollToElement("txtLName");
        return false;
      }
  
      if (errorFlag == 0) {
        setClickedSlide({ slide: "next06" });
        scrollToElement("slide7");
  
        setTimeout(() => {
          window.scrollTo({
            top: scrollfrmv2div.current.offsetTop,
            left: 0,
            behavior: "smooth",
          });
        }, 150);
  
        let fullName =
          txtFName.charAt(0).toUpperCase() + txtFName.slice(1).toLowerCase();
        fullName += " ";
        fullName +=
          txtLName.charAt(0).toUpperCase() + txtLName.slice(1).toLowerCase();
        localStorage.setItem("FullName", fullName);
      }
      return false;
    };
  
    const formSubmit = async () => {
      let errorFlag = 0;
  
      const values = getValues();
      const form = splitForm.current;
      const formData = values;
  
      const txtEmail = values["txtEmail"];
      const txtPhone = values["txtPhone"];
  
      let txtEmailResult;
      let txtPhoneResult;
  
      formData.seeking_a_will = seekingWillList;
      formData.what_is_important = whatIsImportantList;
      // formData.life_insurance = lifeinsuranceList;
  
      if (slideValues.question !== "homeowner" && slideValues.value !== "13") {
        txtPhoneResult = await trigger("txtPhone");
        if (txtPhoneResult) {
          if (txtPhone.trim() == "" || txtPhone.trim().length == 0) {
            scrollToElement("txtPhone");
            console.log("1");
            return false;
          } else {
            txtEmailResult = await trigger("txtEmail");
          }
        } else {
          scrollToElement("txtPhone");
          return false;
        }
  
  
        // Email and Phone
        if (txtEmailResult) {
          if (txtEmail.trim() == "" || txtEmail.trim().length == 0) {
            scrollToElement("txtEmail");
            return false;
          }
        } else {
          scrollToElement("txtEmail");
            return false;
        }
  
        if (txtEmail && txtPhone) {
          if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
            errorFlag = 0;
          } else {
            return false;
          }
        }
      }
      if (errorFlag == 0) {
        setBtnDisabled(true);
        if (formData.address1 !== "") {
          const txtAddress3 = form["txtAddress3"].value;
          const txtCountry = form["txtCountry"].value;
          const txtCounty = form["txtCounty"].value;
  
          formData.txtAddress3 = txtAddress3;
          formData.txtCounty = txtCounty;
          formData.txtCountry = txtCountry;
  
          const txtAddress4 = form["txtAddress4"].value;
          const txtDistrict = form["txtDistrict"].value;
          const txtLocality = form["txtLocality"].value;
          formData.txtAddress3 = txtAddress3;
          formData.txtAddress4 = txtAddress4;
          formData.txtDistrict = txtDistrict;
          formData.txtLocality = txtLocality;
  
          formData.pCode_manual = form["pCode_EntryType"].value;
        }
  
        const formSubmitResult = await saveDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          splitName,
          queryString,
          visitorParameters.data
        );
  
        if (formSubmitResult.data.status === "Success" && slideValues.question !== "homeowner" && slideValues.value !== "13") {
          GtmDataLayer({
            question: "contact details",
            answer: "Completed",
          });
          if (queryString != null) {
            if (queryString.indexOf("&" + field + "=") !== -1) {
              history.push("/fbpixel"); // fb fixel firing
            } else {
              history.push(
                "/analyse?uuid=" + visitorParameters.visitor_parameters.uuid
              );
            }
          } else {
            history.push(
              "/analyse?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        }
      }
    };
  
    return (
      <>
        <div ref={scrollfrmv2div}>
          <form
            ref={splitForm}
            name="split_form"
            id="user_form"
            method="POST"
            autoComplete="off"
          >
            <BasicQuestionnaireOne
              className={`form-group text-center ${state.showSlide1}`}
              textHeading="Were you born before 1972?"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
            />
            <BasicQuestionnaireTwo
              className={`form-group text-center ${state.showSlide2}`}
              textHeading="Are you currently married?"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
            />
            <BasicQuestionnaireFive
              className={`form-group text-center ${state.showSlide3}`}
              textHeading="Do you have any children?"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
            />
            {/* <BasicQuestionnaireThree
              className={`form-group text-center ${state.showSlide3}`}
              textHeading="Why are you seeking a will?"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
              checkSeekingWillList={checkSeekingWillList}
            /> */}
            <BasicQuestionnaireFour
              className={`form-group text-center ${state.showSlide4}`}
              textHeading="Are you a homeowner?"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
              getValues={getValues}
              splitName={splitName}
            />
  
            <BasicQuestionnaireSix
              className={`form-group text-center ${state.showSlide4_5}`}
              textHeading="Please tell us what is most important to you (check all answers that may apply)"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
              updateCheckedList={setWhatIsImportantList}
            />
            <PostCode
              className={`form-group text-center ${state.showSlide5}`}
              textHeading="Great! Let's confirm that you live in qualifying postcode"
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              splitForm={splitForm}
              setError={setError}
              getValues={getValues}
              clearErrors={clearErrors}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
            />
            <BasicQuestionnaireEight 
               className={`form-group text-center ${state.showSlide5_5}`}
               textHeading="Who are you personalising the quote for?"
               validation={register}
               slideChange={slideChange}
               slidePreChange={slidePreChange}
               getValues={getValues}
               splitName={splitName}
            />
            <BasicDetails
              className={`form-group text-center ${state.showSlide6}`}
              textHeading="Please Enter your Full Name"
              validation={register}
              slidePreChange={slidePreChange}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              validationMsg={errors}
              basic_onchangehandle={basic_onchangehandle}
              BasicDetailsSubmit={BasicDetailsSubmit}
              reff={scrollRef}
            />
            <DOB
              className={`form-group text-center ${state.showSlide7}`}
              textHeading="Your date of birth"
              validation={register}
              validationMsg={errors}
              trigger={trigger}
              splitForm={splitForm}
              setError={setError}
              slidePreChange={slidePreChange}
              slideChange={slideChange}
              getValues={getValues}
            />
            <ContactDetails
              className={`form-group text-center ${state.showSlide8}`}
              slidePreChange={slidePreChange}
              validation={register}
              slideChange={slideChange}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              validationMsg={errors}
              basic_onchangehandle={basic_onchangehandle}
              namevalidate={namevalidate}
              emailValidCheck={emailValidCheck}
              phoneValidCheck={phoneValidCheck}
              reff={scrollRef}
              disabled={btnDisabled}
              formSubmit={formSubmit}
            />
            <BasicQuestionnaireThree
              className={`form-group text-center ${state.showSlide10}`}
              textHeading="Why are you looking for over 50 life insurance"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
              checkSeekingWillList={checkSeekingWillList}
              formSubmit={formSubmit}
            />
          <img className={(loading === 0 || loading === 2) && "d-none"} src={loader_gif} alt="Loading..." style={{
            display: 'block',
            margin: 'auto',
          }} />
          {loading === 2 && (
            <CallOption
              className={`form-group text-center ${state.showSlide11}`}
              textHeading="Call Now"
              validation={register}
              slideChange={slideChange}
              slidePreChange={slidePreChange}
              checkSeekingWillList={checkSeekingWillList}
              phoneNumber={phoneNumber}
              formattedPhoneNumber={formattedPhoneNumber}
              splitName={splitName}
              formSubmit={formSubmit}
              slideValues={slideValues}
            />
            )}
          </form>
        </div>
        {/* <!--  ========= FormSection  Area End  ========= --> */}
      </>
    );
  };
  
  export default FormMWL_V2B;
  
import React, { useState } from "react";
import ProgressBar from "../../Layouts/MWL_V2B/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import arrow from "../../../../assets/MWL_V2B/img/arrow.png";
import icosafe from "../../../../assets/MWL_V2B/img/ico-safe.png"


const BasicQuestionnaireeight = ({
  className,
  slideChange,
  textHeading,
  slidePreChange,
  validation
}) => {
  const nextSlide = async (e) => {
    changeActiveStatus(e);
    slideChange(e);
  };

  const preSlide = async (e) => {
    document.getElementById("start-but").classList.remove('hide');
    slidePreChange(e);
  };

  const [activestatus, setActivestatus] = useState(0);
  const changeActiveStatus = (e) => {
    if (e.target.value == 17) {
      setActivestatus(17);

    } else if (e.target.value == 18) {
      setActivestatus(18);
    }
  }

  return (
    <>
      <div id="slide2" className={`col-12 top_minus_1 ${className}`}>
      <ProgressBar value="40" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3">
          <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center">
            <div className="row">
              <div className="form-group">
              <div className="form-group">
                      <label className="text-center mb-1">{textHeading}</label>
                      <RadioButton
                        tabIndex="1"
                        value="14"
                        id="myself"
                        name="will_for"
                        className="radio-button custom-radio-button"
                        labelName="Myself"
                        labelClassName={activestatus == 1 ? "icon_option smoker3 m_family load sctop active" :
                          "icon_option smoker3 m_family load sctop"
                        }
                        onClick={nextSlide}
                        validation={validation()}
                      />
                      <RadioButton
                        tabIndex="2"
                        value="15"
                        id="mypartner"
                        name="will_for"
                        className="radio-button custom-radio-button"
                        labelName="Myself & Partner"
                        labelClassName={activestatus == 2 ? "icon_option smoker3 m_family load mobload sctop active" :
                          "icon_option smoker3 m_family load mobload"
                        }
                        onClick={nextSlide}
                        validation={validation()}
                      />
                      {/* <RadioButton
                        tabIndex="3"
                        value="16"
                        id="singlewills"
                        name="will_for"
                        className="radio-button custom-radio-button"
                        labelName="(2) Single Wills"
                        labelClassName={activestatus == 3 ? "icon_option smoker3 m_family load mobload sctop active" :
                          "icon_option smoker3 m_family load mobload sctop"
                        }
                        validation={validation()}
                        onClick={WillValidation}
                      /> */}
                      <span id="fullDOB_err" className="error_msg error"></span>
                    </div>
              </div>
              <span id="cover_for_err" className="error_msg error"></span>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 bord text-center">
          <div className="col-12 text-center mt-1">
            <a id="back2" name="back23" className="back_btn sctop1"  onClick={preSlide}><img src={arrow} alt=""/> Previous </a>
          </div>
          <div className="text-center safe-text">
            <img src={icosafe} alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};
export default BasicQuestionnaireeight;

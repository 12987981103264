import React, { useState, useRef, useEffect } from "react";
import ProgressBar from "./ProgressBar";
import CustomCheckBox from "../../../UI/CustomCheckBox";
import { useUserClicks } from "../../../../Hooks/useUserClicks";
import { UUID } from '../../../../Utility/UUID';
import useJourneyStats from '../../../../Hooks/useJourneyStats';
import confetti from 'canvas-confetti';
import GtmDataLayer from "../../GtmDataLayer";
import PixelTrigger from "../../../../Utility/PixelTrigger";

const CallOption = ({
  className,
  slideChange,
  slidePreChange,
  textHeading,
  setLifeinsuranceList,
  phoneNumber,
  splitName,
  formSubmit,
  // setCurrentSlide,
  slideValues,
}) => {
  const [lifeinsuranceList] = useState([]);
  const [showError, setError] = useState('hide');
  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };
  const saveJournyStats = useJourneyStats();
  const tyPixelTrigger = PixelTrigger();

  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  // const journeyStatus = async (values) => {
  //   const { slide_type, slide_question, slide_value, type } = values;

  //   await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  // };

  const scrollSlide11 = useRef(null);

  useEffect(() => {
    if (slideValues.question === "homeowner" && slideValues.value === "13") {
      formSubmit();
      setTimeout(() => {
        confetti({
          particleCount: 100,
          spread: 100,
          origin: { y: 0.9 },
        });
      }, 0);
    }
  }, [slideValues])

  const handleOptionClick = (option) => {
    GtmDataLayer({
      question: "ringba call",
      answer: "Completed",
    });
    saveUser('CALL_CLICK', option);
    saveJournyStats(uuid,'button','CALL_CLICK','','click',splitName);
    tyPixelTrigger({
      splitName : splitName,
    });
    // saveJournyStats({
    //   slide_type: 'button',
    //   slide_question: 'CALL_CLICK',
    //   slide_value: '',
    //   type: 'click',
    // });
  }


  return (
    <>
      <div ref={scrollSlide11} id="slide11" className={`col-12 ${className}`}>
        <ProgressBar value="100" />
        <div className="col-lg-12 col-md-12 col-sm-12 px-3 bord text-center">
          <p className="mx-md-5" style={{fontSize:"18px"}}>
            <span style={{fontSize:"25px"}}>Congratulations! 🤩🤩🥳🥳🥳 </span><br />
            Tap the button below to speak with one of our licensed agents. <br />
            This will guarantee you the lowest possible price and help you qualify, it will only take you 2 to 3 minutes.
          </p>
          {/* <input type="button" id="next11" name="next11" className="next-but btn flasher my-bts sctop1 next03" value="Call Now" autoComplete="off" onClick={() => handleOptionClick(1)}  href={`tel: ${phoneNumber}`} /> */}
          <a
                            className='next-but btn flasher my-bts sctop1 next03 call-btn'
                            onClick={() => handleOptionClick(1)}
                            href={`tel: ${phoneNumber}`}>
                              <label className="next-but btn my-bts flasher2 sctop1">Call Now</label>
                          </a>

          {/* <div className="col-12 text-center mt-1">
            <a id="back9" name="back9" className="back_btn sctop" onClick={slidePreChange}><img src="/assets/MWL_V1/img/arrow.png" alt=""/>  Previous </a>
          </div> */}
          <div className="text-center safe-text">
            <img src="/assets/MWL_V1/img/ico-safe.png" alt="Safe, Secure &amp; Confidential" width="12" height="15" />Safe | Secure | Privacy Protected
          </div>
        </div>
      </div>
    </>
  );
};

export default CallOption;

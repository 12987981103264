import React, { useEffect, useContext } from "react";
import "../../assets/MWL_V1/css/MWL_V1.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";

const Analyse = () => {
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      history.push(
        "/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className="animatelogo">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <a>
                <img
                  src="/assets/MWL_V1/img/logo.png"
                  className="d-block mx-auto logo animated  bounceInDown"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="animategif">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 loader text-center">
              <div className="col-lg-10 offset-lg-1 form-section">
                <div id="myDiv" className="space text-center">
                  <img src="/assets/MWL_V1/img/loader.gif" alt="" />
                  <h4>
                    <b>Analysing Best Quotes.....</b>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Analyse;
